import mixins from '@/mixins'

const tableConfig = {
  leasing: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'serial', propType: 'String' },
      { propName: 'invoice', propType: 'String' },
      { propName: 'portion', propType: 'String' },
      { propName: 'provider', propType: 'String' },
      { propName: 'description', propType: 'String' },
      { propName: 'activeNumber', propType: 'String' },
      { propName: 'acquisitionDate', propType: 'Date' },
      { propName: 'originalAcquisitionDate', propType: 'Date' },
    ],
    tableColumnsProps: [
      {
        label: 'Valor',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Data aquisição',
        prop: 'acquisitionDate',
        minWidth: 150,
        formatter: formatTableDate,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Descrição',
        prop: 'description',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Quantidade',
        prop: 'quantity',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Número serial',
        prop: 'serial',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Número ativo',
        prop: 'activeNumber',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Fornecedor',
        prop: 'provider',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Nota fiscal',
        prop: 'invoice',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Data aquisição original',
        prop: 'originalAcquisitionDate',
        minWidth: 200,
        formatter: formatTableDate,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Parcela',
        prop: 'portion',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  external: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'name', propType: 'String' },
      { propName: 'activity', propType: 'String' },
      { propName: 'formation', propType: 'String' },
      { propName: 'schooling', propType: 'String' },
      { propName: 'role', propType: 'String' },
      { propName: 'hours', propType: 'String' },
      { propName: 'register', propType: 'Register' },
    ],
    tableColumnsProps: [
      {
        label: 'Valor',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Colaborador',
        prop: 'name',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'CNPJ/CPF',
        prop: 'register',
        minWidth: 150,
        formatter: handleFormatRegister,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Função',
        prop: 'role',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Horas',
        prop: 'hours',
        minWidth: 150,
        formatter: formatHours,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Escolaridade',
        prop: 'schooling',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Formação',
        prop: 'formation',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Atividade',
        prop: 'activity',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  training: {
    searchColumns: [
      { propName: 'name', propType: 'String' },
      { propName: 'register', propType: 'Register' },
    ],
    tableColumnsProps: [
      {
        label: 'Colaborador',
        prop: 'name',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'CNPJ/CPF',
        prop: 'register',
        minWidth: 150,
        formatter: handleFormatRegister,
        headerAlign: 'left',
        align: 'left',
      },
    ],
    tableIconColumnsProps: [],
  },
}

function formatTableValue(row, column, cellValue, index) {
  return mixins.methods.handleFormatValue(cellValue)
}

function formatTableDate(row, column, cellValue, index) {
  return mixins.methods.handleFormatDate(cellValue)
}

function formatTableData(row, column, cellValue, index) {
  return cellValue !== undefined && String(cellValue)?.length > 0
    ? cellValue
    : '-'
}

function handleFormatRegister(row, column, cellValue, index) {
  return mixins.methods.handleFormatRegister(cellValue)
}

function formatHours(row, column, cellValue, index) {
  return Number(cellValue) === 0 || isNaN(Number(cellValue))
    ? '-'
    : Number(cellValue).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
}

export default tableConfig
