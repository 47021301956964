<template>
  <div>
    <section class="table-container">
      <el-table
        :data="handlerSearch"
        class="table-container__inner"
        v-loading="loading"
      >
        <el-table-column
          v-for="(element, index) in tableColumnsProps"
          :key="`table-column-${element.label}-${index}`"
          :prop="element.prop"
          :label="element.label"
          :min-width="element.minWidth"
          :formatter="element.formatter"
          show-overflow-tooltip
          :align="element.align"
          :header-align="element.headerAlign"
          sortable
        />
        <el-table-column
          v-for="(column, index) in tableIconColumnsProps"
          :key="`table-icon-column-${column.label}-${index}`"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align"
          :header-align="column.headerAlign"
          show-overflow-tooltip
          sortable
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="handleIconControl(scope)"
              placement="top"
              :content="handleIconText(scope)"
              :open-delay="500"
            >
              <i class="el-icon-success icon-success" />
            </el-tooltip>
            <el-tooltip
              v-else
              placement="top"
              :content="handleIconText(scope)"
              :open-delay="500"
            >
              <i class="el-icon-warning icon-warning" />
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="Ações"
          align="center"
          min-width="75"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-popover placement="left" trigger="hover" :open-delay="300">
              <el-row class="actions-popover">
                <el-button class="btn-action" @click="handleEdit(scope.row)"
                  >Editar</el-button
                >
                <el-button class="btn-action" @click="handleRemove(scope.row)">
                  Remover
                </el-button>
              </el-row>
              <el-button
                slot="reference"
                icon="el-icon-more"
                class="btn-icon"
              />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </section>
  </div>
</template>

<script>
import tableConfig from '@/utils/entries'

export default {
  name: 'EntriesTablePage',
  props: {
    informations: Array,
    searchText: String,
    loading: {
      type: Boolean,
      default: () => false,
    },
    type: String,
  },
  data() {
    return {
      tableData: [],
      tableBuffer: [],
      tableColumnsProps: [],
      tableIconColumnsProps: [],
      searchColumns: [],
    }
  },
  watch: {
    informations() {
      this.tableData = this.informations
      this.tableBuffer = this.informations
    },
  },
  methods: {
    handleDefineTableProperties() {
      const { tableColumnsProps, tableIconColumnsProps, searchColumns } =
        tableConfig[this.type]
      this.searchColumns = searchColumns
      this.tableColumnsProps = tableColumnsProps
      this.tableIconColumnsProps = tableIconColumnsProps
    },
    handleEdit(row) {
      this.$emit('onEditClick', row)
    },
    handleRemove(row) {
      this.$confirm(
        `Tem certeza que quer deletar o lançamento ${
          this.type === 'leasing' ? row.description : row.name
        }?`,
        'Deletar lançamento',
        {
          confirmButtonText: 'Deletar',
          cancelButtonText: 'Cancelar',
          confirmButtonClass: 'primary-button',
          cancelButtonClass: 'secondary-button',
          type: '',
        }
      )
        .then(() => {
          this.$emit('onRemoveClick', row)
        })
        .catch(() => {})
    },
  },
  computed: {
    handlerSearch() {
      const pageContent = this.handlePageContent(
        this.searchText,
        this.searchColumns,
        this.tableBuffer
      )
      return pageContent
    },
  },
  created() {
    this.tableData = this.informations
    this.tableBuffer = this.informations
    this.handleDefineTableProperties()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
